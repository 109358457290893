import { usePageContext } from "vike-react/usePageContext";

const ErrorPage = () => {
  const pageContext = usePageContext();

  return (
    <div className="container mx-auto m-auto pt-8 md:pt-16 pb-[50px]">
      <header className="flex flex-col items-center m-auto gap-5 md:gap-6 mb-8 md:mb-16">
        <h1>{pageContext.is404 ? "Page not found!" : "Oops! Something went wrong."}</h1>
        {!pageContext.is404 && <h3>We've been notified of the issue and will look into it as soon as we can.</h3>}
        <button
          onClick={() => window.location.href = "/"}
          className="bg-fuschia md:px-6 md:py-2 font-bold font-space text-center hover:text-black cursor-pointer border border-offBlack rounded-full shadow-main"
        >
          ⬅ Back to home
        </button>
      </header>
    </div>
  );
};

export default ErrorPage;
